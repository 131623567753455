import React from 'react'
import Carousel from 'react-elastic-carousel'
import './Slider.scss'

const slides = [
  {
    path: require('@images/floor_preview__1.png'),
    alt: 'схема первого этажа'
  },
  {
    path: require('@images/floor_preview__1-2.png'),
    alt: 'схема первого этажа'
  },
  {
    path: require('@images/floor_preview__2.png'),
    alt: 'схема второго этажа'
  },
  {
    path: require('@images/floor_preview__3.png'),
    alt: 'схема третьего этажа'
  },
  {
    path: require('@images/floor_preview__4.png'),
    alt: 'схема крыши этажа'
  }
]

const btnText = [
  '1',
  '1<sup>2</sup>',
  '2',
  '3',
  '<svg width="18" height="8" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 7L9 1L17 7" stroke="black"/></svg>К'
]

const createBtnText = (id: number) => ({
  __html: btnText[id]
})

const Slider = () => {
  return (
    <Carousel
      verticalMode
      itemsToShow={1}
      itemsToScroll={1}
      transitionMs={0}
      showArrows={false}
      renderPagination={({ pages, activePage, onClick }) => {
        return (
          <div className="pagination" id="map">
            {pages.map((page, id) => {
              const isActivePage = activePage === page
              return (
                <button
                  className="pagination__btn"
                  key={page}
                  onClick={() => onClick(page + '')}
                  data-active={isActivePage}
                >
                  <div className="text" dangerouslySetInnerHTML={createBtnText(id)} />
                </button>
              )
            })}
          </div>
        )
      }}
    >
      {slides.map(
        ({ path, alt }) => {
          return (
            <div className={'test2'}>
              <div className={'test'} style={{ backgroundImage: `url(${path})` }}></div>
            </div>
          )
        }
        // <img className="slider__image" src={path} alt={alt} key={path} />
      )}
    </Carousel>
  )
}

export default Slider
