import loadable from '@loadable/component'

export const Page = loadable(() => import('./Page/Page'))
export const Container = loadable(() => import('./Container/Container'))
export const Header = loadable(() => import('./Header/Header'))
export const Footer = loadable(() => import('./Footer/Footer'))
export const Pagination = loadable(() => import('./Pagination/Pagination'))
export const SEO = loadable(() => import('./SEO'))
export const Modals = loadable(() => import('./Modals/Modals'))

export { default as Modal } from './Modal/Modal'
export { default as Slider } from './Slider/Slider'
